define("mldp/utils/setup-query-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = setupQueryParams;

  function setupQueryParams(keys) {
    return function () {
      let additionalKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
      let qps = [...additionalKeys, ...keys];
      return generateRouteQueryParams(qps);
    };
  }
  /**
   * Create an object of query param keys that have refreshModel: true
   * 
   * @param {Array[String]} keys 
   */


  function generateRouteQueryParams(keys) {
    return keys.uniq().reduce((obj, key) => {
      obj[key] = {
        refreshModel: true
      };
      return obj;
    }, {});
  }
});