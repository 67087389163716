define("mldp/utils/class-op", ["exports", "@ember-data/adapter", "@ember/polyfills", "@ember/debug", "mldp/utils/string-template"], function (_exports, _adapter, _polyfills, _debug, _stringTemplate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = classOp;

  /**
   * Create functions on the adapter that query the server and return the model
   * 
   * When using on the adapter, you must define `modelName: <singular dasherized model name>` on the adapter.
   * 
   * Example:
   * 
   * ```js
   * modelName: 'tlk-deer-season',
   *
   * changeCurrent: classOp({
   *   path: 'current/{id}',
   *   type: 'put',
   *   single: true
   * })
   * ```
   * 
   * Which can be used like so:
   * 
   * ```js
   * let adapter = this.store.adapterFor('tlk-deer-season');
   * let options = {
   *   params: {
   *     id: 'test'
   *   },
   *   data: {
   *     something: 'here'
   *   }
   * };
   * 
   * // queries PUT /api/tlkdeerseason/current/test
   * adapter.changeCurrent(options).then((seasonModel) => {
   *   // showing it's a promise and returns the model
   *   return seasonModel;
   * });
   * ```
   * 
   * The `findCurrent` method can take options, i.e. `findCurrent(payload)`.
   * 
   * - `payload` - Optional, Object
   *   - `data` - Optional, Object, data passed to the body of the request, for POST, etc.
   *   - `params` - Optional, Object, data passed to the url and formatted,
   *      e.g. { name: 'bob' } turns `path: 'something/{name}'` to 'something/bob'.
   *   - `query` - Optional, Object, data passed as query params.
   * 
   * @param {Object} options Required object
   *    - `path` - Required, String. Appended to the url generated by `buildURL` and `urlType`.
   *      Can take template strings, e.g. `current/{userId}` which would be populated via `params` from the payload, see above.
   *    - `type` - Optional, String. HTTP method type. Defaults to 'GET'.
   *    - 'urlType` - Optional, String. Used to create the base url and call certain methods on the adapter. See https://github.com/emberjs/data/blob/v2.12.0/addon/-private/adapters/build-url-mixin.js#L55.
   *      Defaults to 'findAll'.
   *    - `returnModel` - Optional, Boolean. Return the model, not the raw payload. Defaults to `true`.
   *    - `single` - Optional, Boolean. Return a single record, or many. Works with `returnModel`. Defaults to `false`.
   *    - `transform` - Optional, Function. Modify the server payload before returning in the promise.
   *    - `modifyPayload` - Optional, Function. Modify the request payload (see example above) before the request to the server is made.
   * @returns {Promise} The model or array of models, depending on the result from the server.
   */
  function classOp(options) {
    return function (payload) {
      if (!(this instanceof _adapter.default)) {
        throw new Error('class-op only works on instances of DS.Adapter');
      }

      if (!this.modelName) {
        throw new Error('You need to define a `modelName` on this adapter, which represents a dasherized singular name for the model.');
      }

      let modelName = this.modelName;
      let returnModel = options.returnModel === undefined ? true : options.returnModel;
      let requestType = (options.type || 'GET').toUpperCase();
      let urlType = options.urlType || 'findAll';
      payload = payload || {};

      if (typeof options.modifyPayload === 'function') {
        payload = options.modifyPayload.call(this, payload);
      }

      this.skipSessionInvalidateOnUnauthorized = true;
      let data = Array.isArray(payload.data) ? [...payload.data] : (0, _polyfills.assign)({}, payload.data);
      let queryHash = (0, _polyfills.assign)({}, payload.query);
      let params = (0, _polyfills.assign)({}, payload.params);
      let fullUrl = buildOperationUrl(this, options.path, urlType, params, queryHash);
      let query = this.ajax(fullUrl, requestType, (0, _polyfills.assign)(options.ajaxOptions || {}, {
        data
      }));

      if (typeof options.transform === 'function') {
        query = query.then(options.transform.bind(this));
      } else if (returnModel) {
        query = query.then(result => {
          if (result !== null && result !== void 0 && result.data) {
            if (options.single) {
              result.data.type = modelName;
            } else {
              result.data = result.data.map(datum => {
                datum.type = modelName;
                return datum;
              });
            }
          }

          return this.store.push(result);
        });
      }

      return query;
    };
  }

  function buildOperationUrl(adapter, opPath, urlType, params, queryHash) {
    (true && !(typeof opPath === 'string') && (0, _debug.assert)('You must provide a path for classOp', typeof opPath === 'string'));
    let modelName = adapter.modelName;
    let path = (0, _stringTemplate.default)(opPath, params);
    let baseUrl = adapter.buildURL(modelName, undefined, undefined, urlType, queryHash);

    if (baseUrl.charAt(baseUrl.length - 1) === '/') {
      return `${baseUrl}${path}`;
    } else {
      return `${baseUrl}/${path}`;
    }
  }
});