define("mldp/models/recommendation", ["exports", "@ember-data/model", "@ember/object/computed", "ember-cp-validations", "mldp/utils/instance-op"], function (_exports, _model, _computed, _emberCpValidations, _instanceOp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    siteId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must be associated with a site'
    }),
    enrollmentId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must be associated with an enrollment'
    }),
    speciesId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must be associated with a species'
    }),
    seasonId: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must be associated with a season'
    }),
    buckTagsAny: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must set buck tags to at least 0',
      disabled: (0, _computed.bool)('model.gender.isAntlerless').volatile()
    }),
    doeTags: (0, _emberCpValidations.validator)('presence', {
      presence: true,
      message: 'A recommendation must set antlerless tags to at least 0',
      disabled: (0, _computed.bool)('model.gender.isBuck').volatile()
    })
  });

  var _default = _model.default.extend(Validations, {
    siteId: (0, _model.attr)('number'),
    enrollmentId: (0, _model.attr)('number'),
    speciesId: (0, _model.attr)('number'),
    seasonId: (0, _model.attr)('string'),
    invalidated: (0, _model.attr)(),
    invalidatedDate: (0, _model.attr)('date'),
    ownerAccepted: (0, _model.attr)(),
    ownerAgentAcceptedDate: (0, _model.attr)('date'),
    ownerAgentAcceptedBy: (0, _model.attr)('number'),
    ownerAgentDeclinedBy: (0, _model.attr)('number'),
    ownerDeclined: (0, _model.attr)(),
    ownerAgentDeclinedDate: (0, _model.attr)('date'),
    biologistComment: (0, _model.attr)('string'),
    notificationDate: (0, _model.attr)('date'),
    buckTagsAny: (0, _model.attr)('number'),
    buckTagsUnbranched: (0, _model.attr)('number'),
    doeTags: (0, _model.attr)('number'),

    /* 
     * Custom Endpoints, rec.accept().then()
     */

    /**
     * Can accept `{ comment: String }` as a payload, i.e.
     * `rec.accept({ comment: 'looks good' })`
     */
    accept: (0, _instanceOp.default)({
      path: 'accept',
      type: 'post'
    }),
    decline: (0, _instanceOp.default)({
      path: 'decline',
      type: 'post'
    }),
    invalidate: (0, _instanceOp.default)({
      path: 'invalidate',
      type: 'post'
    }),
    initTags: (0, _instanceOp.default)({
      path: 'inittags',
      type: 'post'
    })
  });

  _exports.default = _default;
});