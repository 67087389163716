define("mldp/components/recommendations/creator-display-card/component", ["exports", "@ember-decorators/component", "@ember/object", "@ember/service", "@ember/component", "mldp/utils/total-from-gender", "moment"], function (_exports, _component, _object, _service, _component2, _totalFromGender, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const classic = __EMBER_CLASSIC_DECORATOR;
  let CreatorDisplayCard = (_dec = (0, _component.tagName)(""), _dec2 = (0, _service.inject)('moment'), _dec3 = (0, _object.computed)('recommendation.{buckTagsAny,buckTagsUnbranched,doeTags}'), _dec4 = (0, _object.computed)('recommendation.{invalidated,ownerAccepted,ownerDeclined,ownerAgentAcceptedDate,ownerAgentDeclinedDate,biologistComment}'), _dec(_class = classic(_class = (_class2 = class CreatorDisplayCard extends _component2.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "moment", _descriptor, this);
    }

    get totalCount() {
      /*
      let buckTags = Number(this.get('recommendation.buckTagsAny'));
      let unbranchedBuckTags = Number(this.get('recommendation.buckTagsUnbranched'));
      let doeTags = Number(this.get('recommendation.doeTags'));
      let buck = isNaN(buckTags) ? 0 : buckTags;
      let unbranchedBuck = isNaN(unbranchedBuckTags) ? 0 : unbranchedBuckTags;
      let doe = isNaN(doeTags) ? 0 : doeTags;
        return buck + unbranchedBuck + doe;*/
      let recommendation = this.recommendation;
      let gender = this.gender;
      return (0, _totalFromGender.default)(gender, recommendation);
    }

    get status() {
      let {
        ownerAccepted,
        ownerDeclined,
        invalidated,
        biologistComment,
        ownerAgentAcceptedDate,
        ownerAgentDeclinedDate,
        ownerAgentName
      } = this.recommendation;
      let message, date;

      if (invalidated) {
        message = biologistComment || ['No biologist comment provided.'];
        return {
          label: 'Invalidated',
          class: '',
          icon: 'error',
          message
        };
      } else if (!ownerAccepted && !ownerDeclined) {
        return {
          label: 'Pending',
          class: 'text-muted',
          icon: 'more_horiz'
        };
      } else if (ownerAccepted) {
        date = (0, _moment.default)(ownerAgentAcceptedDate).utc();
        message = [];

        if (date._isValid) {
          message.push('Date Accepted: ' + date.format('MM/DD/YYYY'));
        }

        if (ownerAgentName) {
          message.push('Accepted By: ' + ownerAgentName);
        }

        return {
          label: 'Accepted',
          class: 'text-success',
          icon: 'check_circle',
          message
        };
      } else if (ownerDeclined) {
        date = (0, _moment.default)(ownerAgentDeclinedDate).utc();
        message = [];

        if (date._isValid) {
          message.push('Date Declined: ' + date.format('MM/DD/YYYY'));
        }

        if (ownerAgentName) {
          message.push('Declined By: ' + ownerAgentName);
        }

        return {
          label: 'Declined',
          class: 'text-danger',
          icon: 'remove_circle',
          message
        };
      }
    }

    invalidate() {
      let recommendation = this.recommendation;
      this.onInvalidate(recommendation);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "moment", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "totalCount", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "totalCount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "status", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "status"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "invalidate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "invalidate"), _class2.prototype)), _class2)) || _class) || _class);
  _exports.default = CreatorDisplayCard;
});