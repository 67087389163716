define("mldp/transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  const duration = 300;

  function _default() {
    this.transition(this.includingInitialRender(), this.fromRoute(() => false), this.toRoute(() => false), this.use('fade', {
      duration
    }));
    /*
      this.transition(
        this.matchSelector('#notifications'),
        this.toValue((to) => {
          return to !== null;
        }),
        this.use('to-left'),
        this.reverse('to-right')
      );*/
  }
});