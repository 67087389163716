define("mldp/adapters/unassigned-rta", ["exports", "mldp/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  const classic = __EMBER_CLASSIC_DECORATOR;

  let UnassignedRta = classic(_class = class UnassignedRta extends _application.default {
    /**
    * Allow querying the records by county e.g.
    * 
    * ```
    * let options = {
    *    countyId: '2'
    * };
    * 
    * return store.query('unassigned-rta', options);
    * ```
    * 
    * @param {*} query 
    * @param {*} modelName 
    */
    urlForQuery(query) {
      let url = super.urlForQuery(...arguments); // if (query.countyId) {
      //   let countyId = query.countyId;
      //   url += `/county/${countyId}`;
      // }

      return url;
    }

  }) || _class;

  _exports.default = UnassignedRta;
});